import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { Offcanvas } from 'react-bootstrap';
import request from '../../services/request';

const IssueAnAdvance = forwardRef(({ employee }, ref) => {
  const [disabled, setDisabled] = useState(false);
  const [show, setShow] = useState(false);
  const [amount, setAmount] = useState(0);
  const [datetime, setDatetime] = useState(new Date());

  useImperativeHandle(ref, () => ({
    showModal() {
      setShow(true);
    }
  }));

  const handleSubmit = async (e) => {
    e.preventDefault();
    setDisabled(true);

    await request('employee/advance/create.php', {
      data: {
        amount,
        datetime: datetime.toISOString(),
        employee_mobile: employee.mobile,
      },
      method: 'POST',
    }).finally(() => {
      setDisabled(false);
    });

    setShow(false);
  };

  const handleAmountChanged = (e) => {
    let value = e.target.value;
    value = Number(value.replace(/^0+(?!\.|$)/, ''))

    if (isNaN(value)) {
      return;
    }
    
    setAmount(value);
  };

  const closeModal = () => {
    setShow(false);
  };

  return (
    <Offcanvas
      show={show}
      onHide={setShow}
      className="offcanvas-end customeoff"
      placement='end'
    >
      <div className="offcanvas-header">
        <h5 className="modal-title" id="#gridSystemModal">Issue an advance</h5>
        <button type="button" className="btn-close"
          onClick={closeModal}
        >
          <i className="fa-solid fa-xmark"></i>
        </button>
      </div>
      <div className="offcanvas-body">
        <div className="container-fluid">
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-xl-6 mb-3">
                <input
                  type="text"
                  name="datetime"
                  value={datetime}
                  className="form-control"
                  required
                  hidden
                />
              </div>
            </div>
            <div className="row">
              <div className="col-xl-6 mb-3">
                <label className="form-label">Amount of money</label>
                <input
                  type="text"
                  name="amount"
                  value={amount}
                  onChange={handleAmountChanged}
                  className="form-control"
                  required
                  placeholder="Amount of money"
                />
              </div>
            </div>
            <div>
              <button
                type="submit"
                className="btn btn-primary me-1"
                disabled={disabled}
              >
                Save
              </button>
              <button
                type="reset"
                onClick={closeModal}
                className="btn btn-danger light ms-1"
                disabled={disabled}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </Offcanvas>
  );
});

export default IssueAnAdvance;