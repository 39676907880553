import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { Offcanvas } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import EventService from '../../../services/EventService';
import { useDispatch } from 'react-redux';
import { setHostelData } from '../../../store/actions/HostelActions';
import { setTovarnaData } from '../../../store/actions/TovarnaActions';

const EventsModal = forwardRef(({ events }, ref) => {
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useImperativeHandle(ref, () => ({
    showModal() {
      setShow(true);
    }
  }));

  const closeModal = () => {
    setShow(false);
  };

  const handleDescriptionClick = (clickedEvent) => {
    let destination = '';
    switch (clickedEvent.type) {
      case EventService.Personal:
        destination = `/employee/${clickedEvent.employee.mobile}`;
        break;
      case EventService.Hostel:
        dispatch(setHostelData(clickedEvent.hostel));
        destination = '/profile-hostels';
        break;
      case EventService.Work:
        dispatch(setTovarnaData(clickedEvent.workplace));
        destination = '/profile-tovarna';
        break;
    }

    if (destination !== '') {
      setTimeout(() => {
        navigate(destination);
      }, 1000);
    }
  };

  return (
    <Offcanvas
      show={show}
      onHide={setShow}
      className="offcanvas-end customeoff"
      placement='end'
    >
      <div className="offcanvas-header">
        <h5 className="modal-title" id="#gridSystemModal">Events information</h5>
        <button type="button" className="btn-close"
          onClick={closeModal}
        >
          <i className="fa-solid fa-xmark"></i>
        </button>
      </div>
      <div className="offcanvas-body">
        <div className="container-fluid">
          {
            events.map((event) => {
              return (
                <div>
                  {
                    event.employee && (
                      <Link to={`/employee/${event.employee.mobile}`}>
                        {`${event.employee.name} - `}
                      </Link>
                    )
                  }
                  <Link to='#' onClick={() => handleDescriptionClick(event)}>
                    {event.description}
                  </Link>
                </div>
              );
            })
          }
        </div>
      </div>
    </Offcanvas>
  );
});

export default EventsModal;