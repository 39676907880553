import React, {
  Fragment,
  useEffect,
  useState,
  useRef,
} from 'react';

import FullCalendar from '@fullcalendar/react';
import daygridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import MainPagetitle from '../../layouts/MainPagetitle';
import moment from 'moment';
import EventsModal from '../EventCalendar/EventsModal';

import '../../../css/calendar.css';
import request from '../../../services/request';

const EventCalendar = () => {
  const mainTitle = 'Event calendar';

  const [events, setEvents] = useState([]);
  const [eventGroups, setEventGroups] = useState([]);
  const [selectedEvents, setSelectedEvents] = useState([]);
  const [useCommonEvents, setUseCommonEvents] = useState(false);

  const eventsModalRef = useRef();
  const calendarRef = useRef(null);

  useEffect(() => {
    fetchEvents();
  }, []);

  const fetchEvents = async () => {
    try {
      const data = await request('employee/events/get.php')
      if (!data.events || !data.grouped_events) {
        console.error(data.message);
        return;
      }

      if (data.events) {
        const events = data.events.map((event) => {
          return {
            ...event,
            start: moment.unix(event.datetime).toDate(),
          };
        });
        setEvents(events);
      }

      if (data.grouped_events) {
        const eventGroups = data.grouped_events.map((event) => {
          return {
            ...event,
            start: moment(event.start).toDate(),
          };
        });

        setEventGroups(eventGroups);
      }

    } catch (error) {
      console.error('Ошибка при загрузке данных:', error);
    }
  };

  const eventContent = (data) => {
    if (useCommonEvents) {
      return (
        <span className="fc-event-title ml-4">{data.event.title}</span>
      );
    }

    return (
      <div className="d-flex justify-around align-items-center">
        <div className="fc-daygrid-event-dot" style={{ borderColor: data.backgroundColor }} />
        <div className="fc-event-title"> - {data.event.title}</div>
      </div>
    );
  };

  const handleEventClick = (data) => {
    const eventGroup = data.event;
    const start = eventGroup.start;
    const color = eventGroup.backgroundColor;

    const formatToCompare = 'YYYY-MM-DD';
    const selectedEvents = events.filter((event) => {
      return event.color == color &&
        moment(start).format(formatToCompare) == moment(event.date).format(formatToCompare);
    });

    setSelectedEvents(selectedEvents);
    eventsModalRef.current.showModal();
  };

  const handleNavLinkClick = (date) => {
    const current = calendarRef.current;
    const calendarApi = current.getApi();

    setUseCommonEvents(true);
    calendarApi.changeView('timeGridCustom', date);
  };

  return (
    <>
      <MainPagetitle mainTitle={mainTitle} />
      <Fragment>
        <div className="container-fluid">
          <section className="flex-1 2xl:mb-0 mb-6">
            <div className="w-full bg-white dark:bg-darkblack-600 sm:px-10 sm:py-5 p-3 rounded-lg">
              <div className="calender-wrapper w-full relative h-full">
                <div className="2xl:w-full lg:w-1/2 w-full rounded-lg bg-white dark:bg-darkblack-600 ">
                  <div className="w-full">
                    <FullCalendar
                      ref={calendarRef}
                      plugins={[
                        daygridPlugin,
                        interactionPlugin,
                        timeGridPlugin,
                        listPlugin,
                      ]}
                      views={{
                        "dayGridDay": "dayGridDay",
                        "timeGridMonth": "timeGridMonth",
                        "dayGridWeek": "dayGridWeek",
                        "timeGridCustom": {
                          type: 'timeGridDay',
                          eventMaxStack: 3,
                        }
                      }}
                      headerToolbar={{
                        start: "dayGridMonth timeGridWeek timeGridDay listMonth",
                        center: "title",
                        end: "prev next",
                      }}
                      eventContent={eventContent}
                      eventClick={handleEventClick}
                      events={useCommonEvents ? events : eventGroups}
                      allDaySlot={false}
                      eventClassNames='fc-slot-attributes'
                      slotEventOverlap={false}
                      initialDate={new Date()} // using new Date(); and set your scheduled on event array
                      navLinks={true} // can click day/week names to navigate views
                      navLinkDayClick={handleNavLinkClick}
                      businessHours={true} // display business hours
                      editable={false}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Fragment>
      <EventsModal
        ref={eventsModalRef}
        events={selectedEvents}
      />
    </>
  );
};

export default EventCalendar;