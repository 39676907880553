import React, { useRef } from "react";
import { Link } from 'react-router-dom';
import IssueAnAdvance from '../../constant/IssueAnAdvance';

const ProfileHeader = (props) => {
  const { employee } = props;
  const advanceModal = useRef();

  const openAdvanceModal = () => {
    advanceModal.current.showModal();
  };

  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          <div className="profile card card-body px-3 pt-3 pb-0">
            <div className="profile-head">
              <div className="profile-info">
                <div className="profile-details">
                  <div className="profile-name px-3 pt-2">
                    <h4 className="text-primary mb-0">{employee.name}</h4>
                    <p>Name</p>
                  </div>
                  <div className="profile-email px-2 pt-2">
                    <h4 className="text-muted mb-0">{employee.email}</h4>
                    <p>Email</p>
                  </div>
                </div>
                <div className="div-addButton">
                  <Link to={"#"}
                    onClick={openAdvanceModal}
                    className="btn btn-primary btn-sm mx-1"
                  >
                    Issue an advance
                  </Link>
                  <Link className="btn btn-secondary btn-sm mx-1">
                    Issue wages
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <IssueAnAdvance
        ref={advanceModal}
        employee={employee}
        Title="Issue am advance"
      />
    </>
  );
};

export default ProfileHeader;