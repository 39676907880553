import React from 'react';
import {
    formatError,
    login,
    runLogoutTimer,
    saveTokenInLocalStorage,
    signUp,
} from '../../services/AuthService';

export const SIGNUP_CONFIRMED_ACTION = '[signup action] confirmed signup';
export const SIGNUP_FAILED_ACTION = '[signup action] failed signup';
export const LOGIN_CONFIRMED_ACTION = '[login action] confirmed login';
export const LOGIN_FAILED_ACTION = '[login action] failed login';
export const LOADING_TOGGLE_ACTION = '[Loading action] toggle loading';
export const LOGOUT_ACTION = '[Logout action] logout action';
export const NAVTOGGLE = 'NAVTOGGLE';

export function Logout(navigate) {
    localStorage.removeItem('bearerToken');
    navigate('/login');

    return {
        type: LOGOUT_ACTION,
    };
}

// FOR LARAVEL -> POSTGREsql:

// export function loginAction(userLogin, password, navigate) {
    
//     return (dispatch) => {
//         login(userLogin, password)
//             .then((response) => {
//                 saveTokenInLocalStorage(response.token);

//                 dispatch(loginConfirmedAction(response));
//                 navigate('/dashboard');
//             })
//             .catch((error) => {
//                 dispatch(loginFailedAction(error.response.data.message));
//             });
//     };
// }

// FOR PHP -> JSON:

export function loginAction(userLogin, password, navigate) {
        
    return async (dispatch) => {
        try {
            // Загрузка данных из JSON файла
            const response = await fetch('https://test1.jobsi.app/registered.json');
            if (!response.ok) {
                throw new Error('Error loading user data file');
            }

            const userData = await response.json();

            // Поиск пользователя с совпадающими логином и паролем
            const foundUser = userData.find(user => user.login === userLogin && user.pw === password);

            if (foundUser) {
                // Если пользователь найден, выполняем аутентификацию
                login(userLogin, password)               
                    .then((response) => {
                        console.log('response', response);
                        saveTokenInLocalStorage(response.token);
                        dispatch(loginConfirmedAction(response));
                        navigate('/dashboard');
                    })
                    .catch((error) => {
                        dispatch(loginFailedAction(error.response.data.message));
                    });
            } else {
                // Если пользователь не найден, обрабатываем ошибку
                alert('Invalid login or password');
                dispatch(loginFailedAction('Invalid login or password'));
            }
        } catch (error) {
            // Обработка ошибки при загрузке файла
            dispatch(loginFailedAction(error.message));
        }
    };
}

export function loginFailedAction(data) {
    return {
        type: LOGIN_FAILED_ACTION,
        payload: data,
    };
}

export function loginConfirmedAction(data) {
    return {
        type: LOGIN_CONFIRMED_ACTION,
        payload: data,
    };
}

export function signupAction(data, navigate) {
    return (dispatch) => {
        signUp(data)
            .then((response) => {
                saveTokenInLocalStorage(response.token);

                dispatch(loginConfirmedAction(response));
                navigate('/dashboard');
            })
            .catch((error) => {
                dispatch(signupFailedAction(error.response.data.message));
            });
    };
};

export function signupFailedAction(data) {
    return {
        type: SIGNUP_FAILED_ACTION,
        payload: data,
    };
}
export function confirmedSignupAction(payload) {
    return {
        type: SIGNUP_CONFIRMED_ACTION,
        payload,
    };
}

// export function confirmedSignupAction(payload) {
//     return {
//         type: SIGNUP_CONFIRMED_ACTION,
//         payload: {
//             // Здесь могут быть моковые данные, которые были бы получены с сервера при успешной регистрации.
//             // Например, информация о зарегистрированном пользователе.
//             userId: 123,
//             username: 'alex',
//             // Другие данные...
//         },
//     };
// }



export function loadingToggleAction(status) {
    return {
        type: LOADING_TOGGLE_ACTION,
        payload: status,
    };
}

export const navtoggle = () => {
    return {
        type: 'NAVTOGGLE',
    };
};