import React from 'react';
import ProjectStatusBlogDonutChart from './ProjectStatusBlogDonutChart';

const ProjectStatusBlog = ({ title, strediskaGraf, strediskoCounts }) => {


    return (
        <>
            <div className="card">
                <div className="card-header pb-0 border-0">
                    <h4 className="heading mb-0">{title}</h4>                    
                    
                </div>
                <div className="card-body">
                <h5 class="h6_strediska">Workplaces</h5>
                    <ProjectStatusBlogDonutChart
                    strediskaGraf={strediskaGraf}
                    strediskoCounts={strediskoCounts}
                    />
                    <div className="project-date">

                        {strediskaGraf.map((stredisko, index)=>(
                            <div className="project-media" key={strediskaGraf}>
                                     
                               <p><span style={{ fontSize: '13px' }}>{stredisko.name}</span> - {strediskoCounts[stredisko.name] || 0} lidi</p>
                            </div>	
                        ))}                        
                    </div>
                </div>
            </div>  
        </>
    );
};

export default ProjectStatusBlog;