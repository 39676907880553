import React, { Fragment, useEffect, useState } from "react";
import { Tab, Nav } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import request from '../../../../services/request';

// import styles
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';

// import JSX Components
import PersonalInfo from '../../EmployeeProfile/PersonalInfo';
import ProfileHeader from '../../EmployeeProfile/ProfileHeader';
import MainPagetitle from '../../../layouts/MainPagetitle';
import EmployeeEvents from '../../EmployeeProfile/EmployeeEvents';

const AppProfile = (props) => {
	const params = useParams();
	const navigate = useNavigate();
	const [employee, setEmployee] = useState();

	useEffect(() => {
		const mobile = params.mobile;
		if (!mobile) {
			navigate('/employee');
			return;
		}

		fetchEmployee(mobile);
	}, []);

	const fetchEmployee = async (mobile) => {
		try {
			const data = await request(`employee/get.php?mobile=${mobile}`);

			if (data.employee) {
				setEmployee(data.employee);
				return;
			}

			console.error(data.message);
		} catch (error) {
			console.error('Ошибка при загрузке данных:', error);
		}
	};

	if (!employee) {
		return (
			<Fragment>

			</Fragment>
		);
	}

	return (
		<>
			<MainPagetitle mainTitle="Employee profile" pageTitle="Employee profile" parentTitle="Workers" />
			<Fragment>
				<div className="container-fluid">
					<ProfileHeader employee={employee} />
					<div className="row">
						<div className="card">
							<div className="card-body">
								<div className="profile-tab">
									<div className="custom-tab-1">
										<Tab.Container defaultActiveKey='About'>
											<Nav as='ul' className="nav nav-tabs">
												<Nav.Item as='li' className="nav-item">
													<Nav.Link to="#about-me" eventKey='About'>About Me</Nav.Link>
												</Nav.Item>
												<Nav.Item as='li' className="nav-item">
													<Nav.Link to="#events" eventKey='Events'>Events</Nav.Link>
												</Nav.Item>
											</Nav>
											<Tab.Content>
												<Tab.Pane id="about-me" eventKey='About'>
													<PersonalInfo employee={employee} />
												</Tab.Pane>
												<Tab.Pane id="events" eventKey='Events'>
													<EmployeeEvents employee={employee} />
												</Tab.Pane>
											</Tab.Content>
										</Tab.Container>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Fragment>
		</>
	);
};

export default AppProfile;
