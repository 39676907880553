import React, { useEffect, useState } from 'react';

const EmployeeEvents = (props) => {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    const { employee } = props;
    fetchEvents(employee.mobile);
  }, []);

  const fetchEvents = async (mobile) => {
    try {
      const response = await fetch(`/employee/events/get.php?mobile=${mobile}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      // Проверяем успешность удаления данных на сервере
      if (!response.ok) {
        console.log('Не удалось удалить данные на сервере');
        throw new Error('Не удалось удалить данные на сервере');
      }

      const data = await response.json();
      if (data.events) {
        setEvents(data.events);
        return;
      }

      console.error(data.message);
    } catch (error) {
      console.error('Ошибка при загрузке данных:', error);
    }
  };

  return (
    <div className="container-fluid pt-4 border-bottom-1 pb-3">
      <h4 className="text-primary mb-4">
        Employee events
      </h4>
      <div id="events-tbl_wrapper" className="dataTables_wrapper no-footer table-responsive">
        <table
          id="events-tbl employeeTable"
          className="table no-footer mb-0"
        >
          <thead>
            <tr>
              <th>Date</th>
              <th>Time</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            {
              events.map((event, index) => {
                return (
                  <tr key={index}>
                    <td>{event.date}</td>
                    <td>{event.time}</td>
                    <td className={`fs-5 text-${event.color}`}>
                      <p className='d-block text-truncate'>
                        {event.description}
                      </p>
                    </td>
                  </tr>
                );
              })
            }
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default EmployeeEvents;