import React from "react";

const PersonalInfo = (props) => {
  const { employee } = props;

  return (
    <div className="profile-personal-info pt-4 border-bottom-1 pb-3">
      <h4 className="text-primary mb-4">
        Personal Information
      </h4>
      <div className="row mb-2">
        <div className="col-3">
          <h5 className="f-w-500"> Name<span className="pull-right">:</span></h5>
        </div>
        <div className="col-9">
          <span>{employee.name}</span>
        </div>
      </div>
      <div className="row mb-2">
        <div className="col-3">
          <h5 className="f-w-500">Email<span className="pull-right">:</span></h5>
        </div>
        <div className="col-9">
          <span>{employee.email}</span>
        </div>
      </div>
      <div className="row mb-2">
        <div className="col-3">
          <h5 className="f-w-500">Telefon<span className="pull-right">:</span></h5>
        </div>
        <div className="col-9">
          <span>{employee.mobile}</span>
        </div>
      </div>
      <div className="row mb-2">
        <div className="col-3">
          <h5 className="f-w-500">Pohlaví<span className="pull-right">:</span></h5>
        </div>
        <div className="col-9">
          <span>{employee.gender}</span>
        </div>
      </div>
      <div className="row mb-2">
        <div className="col-3">
          <h5 className="f-w-500">Ubytovna<span className="pull-right">:</span></h5>
        </div>
        <div className="col-9">
          <span>{employee.ubytovna} Room - {employee.room}</span>
        </div>
      </div>
      <div className="row mb-2">
        <div className="col-3">
          <h5 className="f-w-500">Status Práce<span className="pull-right">:</span></h5>
        </div>
        <div className="col-9">
          <span>{employee.stredisko}</span>
        </div>
      </div>
      <div className="row mb-2">
        <div className="col-3">
          <h5 className="f-w-500">Country<span className="pull-right">:</span></h5>
        </div>
        <div className="col-9">
          <span>{employee.country}</span>
        </div>
      </div>
    </div>
  );
};

export default PersonalInfo;